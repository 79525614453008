<template>
  <div class="subject-add-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses'}">教学内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses/subject'}">课程项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加课程项目</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <el-form-item label="归属课程" prop="tid_course">
          <el-select v-model="pageForm.tid_course" placeholder="请选择归属课程">
            <el-option :label="item.title" :value="item.m_id" v-for="item in courseList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称" prop="title">
          <el-input v-model="pageForm.title"></el-input>
        </el-form-item>
        <el-form-item label="项目类型" prop="type">
          <el-radio-group v-model="pageForm.type">
            <el-radio :label="1">滚动图</el-radio>
            <el-radio :label="2">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="内容" prop="img_content" v-if="pageForm.type === 1">
          <upload :urlArr="pageForm.img_content" :filed="'img_content'" :max-num="100" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item label="内容" prop="video_content" v-if="pageForm.type === 2">
          <upload :url="pageForm.video_content" :filed="'video_content'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即创建</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../common/Upload'
export default {
  name: 'SubjectAdd',
  components: {
    Upload
  },
  data () {
    const checkvideo = (rule, value, callback) => {
      if (value !== '') {
        // 获取最后一个.的位置
        const index = value.lastIndexOf('.')
        // 获取后缀
        const ext = value.substr(index + 1)
        if (['mp4', 'avi'].indexOf(ext.toLowerCase()) !== -1) {
          callback()
        } else {
          callback(new Error('文件类型不符'))
        }
      } else {
        callback()
      }
    }
    return {
      courseList: [],
      pageForm: {
        tid_course: '',
        title: '',
        type: 1,
        img_content: [],
        video_content: ''
      },
      pageRules: {
        tid_course: [
          { required: true, message: '请选择归属课程', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写项目名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        video_content: [
          { validator: checkvideo, message: '文件类型不正确', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getCourseList()
  },
  methods: {
    // 获取课程列表
    async getCourseList () {
      const { data: res } = await this.$http.get('/course-list')
      if (res.code === 200) {
        this.courseList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    submit () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/subject', this.pageForm)
          if (res.code === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/content/courses/subject')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    reset () {
      this.$refs.pageRef.resetFields()
    },
    // 上传组件返回值
    checkedFile (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delFile (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.page-form{
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}
</style>
